    
import axios from 'axios';

export const NUM_POSTS_PER_BATCH = 20;

// export const API_URL = 'http://127.0.0.1:3000/api';
// export const AVATAR_URL = 'http://127.0.0.1:3000/uploads/avatar/';

export const API_URL = 'https://api.sonic-instruments.co/api';
export const AVATAR_URL = 'https://api.sonic-instruments.co/uploads/avatar/';

export const products = [
    {name: 'MO-Band', needsShipping: true}, 
    {name: 'S-Board', needsShipping: false}, 
    {name: 'S-Board Deluxe', needsShipping: false}, 
    {name: 'S-Board Modifier Pack', needsShipping: false},
    {name: 'Bokeh', needsShipping: false}, 
    {name: 'DieRoboter', needsShipping: false},
    {name: 'ADSR', needsShipping: false},
    {name: 'Regulator', needsShipping: false},
    {name: 'Dispatcher', needsShipping: false},
    {name: 'CVWriter', needsShipping: false},
];
export const softwares = ["S-Board"];
export const productMenu = [
    {name: "S-Board", group: 'S-Board', url: '/s-board', sub: 0,},
    {name: "Deluxe bundle", group: 'S-Board', url: '/s-board/deluxe', sub: 1},
    {name: "Modifier pack", group: 'S-Board', url: '/s-board/mod_pack', sub: 1},
    {name: "Bokeh", group: 'S-Board', url: '/s-board/bokeh', sub: 1},
    {name: "dieRoboter", group: 'S-Board', url: '/s-board/dieRoboter', sub: 1},
    {name: "ADSR", group: 'S-Board', url: '/s-board/adsr', sub: 1},
    {name: "Regulator", group: 'S-Board', url: '/s-board/regulator', sub: 1},
    {name: "Dispatcher", group: 'S-Board', url: '/s-board/dispatcher', sub: 1},
    {name: "CVWriter", group: 'S-Board', url: '/s-board/cvwriter', sub: 1},
    {name: "MO-Band", group: 'MO-Band', url: 'mo-band', sub: 0}, 
    {name: "S-Motion", group: 'MO-Band', url: 's-motion', sub: 1}
];

import mb_thumbnail from './src/assets/mb_thumbnail.jpg';


const genTemplete = (preheader, content) => {
    return `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <title>Sonic Instruments Letter Head</title>
            <meta name="x-apple-disable-message-reformatting">
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        </head>
        <body style="margin: 0; padding: 0; font-family: Arial, Helvetica, sans-serif;width:100% !important; -webkit-text-size-adjust:none; -ms-text-size-adjust:none;">
            <table align="center" cellpadding="0" cellspacing="0" style="border-collapse: collapse; width: 100%; max-width: 600px;">
                <tr>
                    <td style="display:none !important;
                    visibility:hidden;
                    mso-hide:all;
                    font-size:1px;
                    color:#ffffff;
                    line-height:1px;
                    max-height:0px;
                    max-width:0px;
                    opacity:0;
                    overflow:hidden;">
                    ${preheader}       
             </td>
                </tr>
                <tr>
                    <td align="center" bgcolor="#f0f0f0" style="padding-top: 10px; padding-right: 0px; padding-left: 0px;">
                        <img alt="logo" src="https://sonic-instruments.co/static/company-logo-7d386b57a65b4222bfe1dc482eae80e0.png" width="80%" height="auto" style="max-width: 500px; min-width: 300px; display: block;" />
                    </td>
                </tr>
                <tr>
                    <td align="center" bgcolor="#f0f0f0" style="padding-right: 10px; padding-bottom: 10px; padding-left: 10px;">
                            <a href="https://sonic-instruments.co/" target="_blank" style="
                                color: black;
                                text-decoration: none;
                                text-transform: uppercase;
                                padding: 12px;
                            "><img src="https://img.icons8.com/ios/50/000000/domain.png" alt="website" width="36px"/></a>
                            <a href="https://www.instagram.com/sonic_instruments/" target="_blank" style="
                                color: black;
                                text-decoration: none;
                                text-transform: uppercase;
                                padding: 12px;
                            "><img src="https://img.icons8.com/ios/64/000000/instagram-new.png" alt="insta" width="36px"/></a>
                            <a href="https://www.youtube.com/channel/UC29A-BPU84CITnZgmvDVx2w" target="_blank" style="
                                color: black;
                                text-decoration: none;
                                text-transform: uppercase;
                                padding: 12px;
                            "><img src="https://img.icons8.com/ios/64/000000/youtube.png" alt="youtube" width="36px"/></a>
                    </td>
                </tr>
                <tr>
                    <td style="padding-top: 40px; padding-left:30px; padding-right:30px; padding-bottom: 40px; font-size: 16px !important; line-height: 1.5 !important; -webkit-text-size-adjust: none;">
                        ${content}
                    </td>
                </tr>
                <tr>
                    <td align="center" bgcolor="#f0f0f0" style="padding-top: 10px; padding-right: 0px; padding-bottom: 10px; padding-left: 0px;">
                        <img src="https://img.icons8.com/color/96/000000/visa.png" width="48px" alt="visa" style="padding-left: 3px; padding-right: 3px;">
                        <img src="https://img.icons8.com/color/96/000000/mastercard.png" width="48px" alt="mastercard" style="padding-left: 3px; padding-right: 3px;">
                        <img src="https://img.icons8.com/color/96/000000/amex.png" width="48px" alt="apple-pay" style="padding-left: 3px; padding-right: 3px;">
                        <img src="https://img.icons8.com/color/96/000000/unionpay.png" width="48px" alt="apple-pay" style="padding-left: 3px; padding-right: 3px;">
                        <img src="https://img.icons8.com/color/96/000000/jcb.png" width="48px" alt="apple-pay" style="padding-left: 3px; padding-right: 3px;">
                        <img src="https://img.icons8.com/color/96/000000/apple-pay.png" width="48px" alt="apple-pay" style="padding-left: 3px; padding-right: 3px;">
                    </td>
                </tr>
                <tr>
                    <td align="center" bgcolor="#f0f0f0" style="padding-right: 0px; padding-bottom: 40px; padding-left: 0px;">
                        <a href="https://sonic-instruments.co/s-board" style="color: #7b5ac8; font-weight: 700; text-decoration: none;">S-Board - Modular Effect Board</a>
                    </td>
                </tr>
            </table>
        </body>
    </html>
`
}

const htmlToText = require('html-to-text');

export const sendEmail = async(to, subject, content) => {
    let config = {
        headers: {
          'Content-Type': 'application/json'
        }
    }
    let plainText = htmlToText.fromString(content);
    let data = {
        from: 'Sonic Instruments <info@sonic-instruments.co>',
        to: to,
        subject: subject,
        html: genTemplete(plainText, content),
    }
    try {
        const { d } = axios.post('https://api.sonic-instruments.co/api/sendEmail', data, config)
        return true
    } catch (err) {
        return false
    }
}

export const getIpCountry = async() => {
    let res = await axios.get('https://ipapi.co/json/', null);
    return res.data.country;
}
export const MOBandObj = 
{
    name: "mo-band",
    includesSoftware : false,
    license: "standard",
    licenseType: "Perpetual",
    expiresAt: "null",
    list:["Fully customizable MIDI compatibility", "Compatible with both PC and Mac", "Latency as low as 1.5ms", "Hi-Res MIDI Support", "8-hour battery life"],
    oneliner:"Motion sensing MIDI controller on your wrist",
    price: 299,
    quantity: 1,
    currency:"$",
    description:"Includes MO-Key (the USB Receiver), S-Motion and S-Board software(Mac, PC)",
    img: mb_thumbnail,
}

