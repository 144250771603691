import axios from 'axios';
import { API_URL } from '../../Global_Config';
import { products as productDirectory } from '../../Global_Config'
// define action names
const ADD_TO_CART = 'ADD_TO_CART';
const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
const GET_CART_FROM_STORAGE = 'GET_CART_FROM_STORAGE';
const SAVE_CART_TO_STORAGE = 'SAVE_CART_TO_STORAGE';
const REMOVE_CART_FROM_STORAGE = 'REMOVE_CART_FROM_STORAGE';
const INC_QUANTITY = 'INC_QUANTITY';
const DEC_QUANTITY = 'DEC_QUANTITY';
const TOTAL_UPDATED = 'TOTAL_UPDATED';
const SHIPPING_UPDATED = 'SHIPPING_UPDATED';
const SHIPPING_ADDRESS_UPDATED = 'SHIPPING_ADDRESS_UPDATED';
const BILLING_ADDRESS_UPDATED = 'BILLING_ADDRESS_UPDATED';
const CURRENCY_CHANGED = 'CURRENCY_CHANGED';
const SHIPPING_CHARGE_UPDATED = 'SHIPPING_CHARGE_UPDATED';
const SUBTOTAL_UPDATED = 'SUBTOTAL_UPDATED';
const EMPTY_CART = 'EMPTY_CART';
const TAX_UPDATED = 'TAX_UPDATED';
const APPLY_PROMOCODE = 'APPLY_PROMOCODE';
const APPLY_PROMOCODE_FAILED = 'APPLY_PROMOCODE_FAILED';
const CLEAR_ERR = 'CLEAR_ERR';
const CAL_TOTAL = 'CAL_TOTAL';
const CLEAR_CART = 'CLEAR_CART';
const REMOVE_PROMOCODE = 'REMOVE_PROMOCODE';
const NEEDS_SHIPPING_CHECKED = 'NEEDS_SHIPPING_CHECKED';

// init state
const initState = {
    items_in_cart : [], 
    currency: 'usd', 
    shipping_address: {
        name: '', 
        email: '',
        organization: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'USA',
    },
    promoCode: '',
    shipping_charge: 0,
    tax: 0,
    err: null,
    needs_shipping: true,
};
// reducer for cart
export function cart(state=initState, action) {
    switch(action.type) {
        case ADD_TO_CART:
            var index = state.items_in_cart.findIndex( i => i.name === action.data.name)
            if (index >= 0) {
                state.items_in_cart[index].quantity += 1;
                return {...state, drawerOpen: true};
            } else {
                state.items_in_cart.push(action.data);
                return {...state};
            }
        case REMOVE_FROM_CART:
            return {...state, items_in_cart: [...state.items_in_cart.slice(0, action.data), ...state.items_in_cart.slice(action.data+1)]};
        case GET_CART_FROM_STORAGE:
            if (typeof window !== 'undefined') {
                const items = JSON.parse(localStorage.getItem('cart'));
                if (items && items.items_in_cart) {
                    return {...state, items_in_cart: items.items_in_cart, subtotal: items.subtotal, total: items.total, shipping_address: items.shipping_address, tax: items.tax, promoCode: items.promoCode};
                } else {
                    return {...state};
                }
            }
            
        case SAVE_CART_TO_STORAGE:
            if (typeof window !== 'undefined') {
                localStorage.setItem('cart', JSON.stringify({items_in_cart: state.items_in_cart, subtotal: state.subtotal, total: state.total, shipping_address: state.shipping_address, tax: state.tax, promoCode: state.promoCode}));
            }
            return {...state};
        case REMOVE_CART_FROM_STORAGE:
                if (typeof window !== 'undefined') {
                    localStorage.removeItem('cart');
                }
            return {...state};
        case INC_QUANTITY:
            state.items_in_cart[action.data].quantity += 1;
            return {...state};
        case DEC_QUANTITY:
            let quantity_overall = 0;
            for (let i=0; i<state.items_in_cart.length; i++) {
                quantity_overall += state.items_in_cart[i].quantity;
            }
            if (quantity_overall > 1) {
                if (state.items_in_cart[action.data].quantity > 1)
                    state.items_in_cart[action.data].quantity -= 1;
                else
                    state.items_in_cart.splice(action.data, 1);
                return {...state};
            } else {
                state.items_in_cart = [];
                return {...state, shipping: undefined, total: undefined, subtotal: undefined, shipping_charge: undefined};
            }
        case TOTAL_UPDATED:
            return {...state, total: action.data};
        case SHIPPING_UPDATED:
            return {...state, shipping: action.data};
        case SHIPPING_ADDRESS_UPDATED:
            return {...state, shipping_address: action.data};
        case BILLING_ADDRESS_UPDATED:
            return { ...state, billing_address: action.data};
        case CURRENCY_CHANGED: 
            return { ...state, currency: action.data};
        case SHIPPING_CHARGE_UPDATED: 
            return { ...state, shipping_charge: action.data};
        case SUBTOTAL_UPDATED:
            return { ...state, subtotal: action.data};
        case EMPTY_CART:
            return { ...state, items_in_cart: []};
        case TAX_UPDATED:
            return { ...state, tax: action.data};
        case APPLY_PROMOCODE:
            const {code, discount, target, precondition} = action.data;
            var total = 0;
            var subtotal = 0;
            state.promoCode = code;
            if (target === 'shipping') {
                if (state.needs_shipping)
                {
                    state.shipping_discount = discount;
                    const amount = Number(discount.replace(/\D/g,''));
                    if (discount.includes('%')) {
                        state.discounted_shipping_charge = state.shipping_charge - state.shipping_charge * (amount / 100);
                    } else {
                        state.discounted_shipping_charge = state.shipping_charge - amount;
                        if (state.discounted_shipping_charge < 0) discounted_shipping_charge = 0;
                    }
                } else {
                    return {...state, err: "Your items don't need shipping after all, the total is not changed."}
                }
                
            } else {
                var found = false
                for (let i=0; i<state.items_in_cart.length; i++) {
                    const item = state.items_in_cart[i];
                    if (item.name.toLowerCase() === target.toLowerCase()) {
                        found = true
                        const item = state.items_in_cart[i];
                        const amount = Number(discount.replace(/\D/g,''));
                        console.log(amount)
                        item.discount = discount;
                        if (discount.includes('%')) {
                            // percentage
                            item.discountedPrice = item.price - item.price * (amount / 100)
                        } else {
                            // price
                            item.discountedPrice = item.price - amount;
                        }
                    }
                    total += (item.discountedPrice ? item.discountedPrice : item.price) * item.quantity
                }
                subtotal = total;
            }
            if (found)
            {
                if (state.shipping_charge) {
                    total += state.discounted_shipping_charge ? state.discounted_shipping_charge : state.shipping_charge;
                }
                total += state.tax ? state.tax : 0;
                if (typeof window !== 'undefined') {
                    localStorage.setItem('cart', JSON.stringify({items_in_cart: state.items_in_cart, subtotal: state.subtotal, total: state.total, shipping_address: state.shipping_address, tax: state.tax, promoCode: code}));
                }
                return {...state, err: null, total: total}
            } else {
                return {...state, err: "No promotional item found in your cart, the total is not changed"}
            }
            
        case APPLY_PROMOCODE_FAILED:
            return {...state, err: action.data};
        case CLEAR_ERR:
            var total = 0;
            return {...state, err: null};
        case CAL_TOTAL:
            var total = 0;
            var subtotal = 0;
            for (let i=0; i<state.items_in_cart.length; i++) {
                const item = state.items_in_cart[i];
                total += item.discountedPrice ? item.discountedPrice : item.price;
            }
            subtotal = total;
            if (state.shipping_charge) {
                total += state.discounted_shipping_charge ? state.discounted_shipping_charge : state.shipping_charge;
            }
            total += state.tax ? state.tax : 0;
            return {...state, total: Math.round(total*1e2)/1e2, subtotal: Math.round(subtotal*1e2)/1e2};
        case CLEAR_CART:
            return initState;
        case REMOVE_PROMOCODE:
            state.promoCode = '';
            state.discounted_shipping_charge = null;
            for (let i=0; i<state.items_in_cart.length; i++) {
                state.items_in_cart[i].discountedPrice = null;;
                state.items_in_cart[i].discount = null;
            }
            if (typeof window !== 'undefined') {
                localStorage.setItem('cart', JSON.stringify({items_in_cart: state.items_in_cart, subtotal: state.subtotal, total: state.total, shipping_address: state.shipping_address, tax: state.tax, promoCode: code}));
            }
            return {...state};
        case NEEDS_SHIPPING_CHECKED:
            state.needs_shipping = action.data;
            return {...state};
        default:
            return state;
    }
}
// action creators for cart

export function add_to_cart(item) {
    return { type: ADD_TO_CART, data: item };
}

export function remove_from_cart(index) {
    return { type: REMOVE_FROM_CART, data: index };
}

export function get_cart_from_storage() {
    return { type: GET_CART_FROM_STORAGE };
}

export function save_cart_to_storage() {
    return { type: SAVE_CART_TO_STORAGE };
}

export function remove_cart_from_storage() {
    return { type: REMOVE_CART_FROM_STORAGE };
}


export function inc_quantity(index) {
    return { type: INC_QUANTITY, data: index };
}

export function dec_quantity(index) {
    return { type: DEC_QUANTITY, data: index };
}

export function total_updated(new_total) {
    return { type: TOTAL_UPDATED, data: Math.round(new_total * 1e2) / 1e2 };
}

export function apply_promocode(code, email) {
    const date = (new Date().toISOString());
    const config = {
        headers:{
            time : date,
            email: email,
        }
    };
    return dispatch => {
        axios.get(`${API_URL}/promocode/${code.toUpperCase()}`, config)
		      .then(res => {
                  dispatch({
                    type : APPLY_PROMOCODE,
                    data : res.data
                })}).catch(err => {
                    dispatch({
                    type : APPLY_PROMOCODE_FAILED,
                    data : err.response.data.message
                })});
    }
}

export function remove_promocode() {
    return { type: REMOVE_PROMOCODE };
}

export function clear_cart() {
    return { type: CLEAR_CART };
}

export function cal_total() {
    return { type: CAL_TOTAL };
}

export function shipping_updated(shipping) {
    return { type: SHIPPING_UPDATED, data: shipping };
}

export function shipping_charge_updated(charge) {
    return { type: SHIPPING_CHARGE_UPDATED, data: Math.round(charge * 1e2) / 1e2 };
}

export function shipping_address_updated(address) {
    return { type: SHIPPING_ADDRESS_UPDATED, data: address };
}

export function billing_address_updated(address) {
    return { type: BILLING_ADDRESS_UPDATED, data: address};
}

export function currency_changed(new_currency) {
    return { type: CURRENCY_CHANGED, data: new_currency};
}

export function subtotal_updated(subtotal) {
    return { type: SUBTOTAL_UPDATED, data: Math.round(subtotal * 1e2) / 1e2  };
}

export function empty_cart() {
    return { type: EMPTY_CART};
}

export function tax_updated(tax) {
    return { type: TAX_UPDATED, data: Math.round(tax * 1e2) / 1e2  };
}

export function clear_error() {
    return { type: CLEAR_ERR };
}

export function check_needs_shipping(items_in_cart) {
    var needs_shipping = false;
    for (let i=0; i<items_in_cart.length; i++) {
        const productName = items_in_cart[i].name.toLowerCase();
        const item = productDirectory.find(item => item.name.toLowerCase() == productName)
        if (item.needsShipping)
        {
            needs_shipping = true;
            break;
        }
    }
    return { type: NEEDS_SHIPPING_CHECKED, data: needs_shipping };
}