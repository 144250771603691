import React from 'react';
import { Provider } from 'react-redux';
import { createStore as reduxCreateStore, applyMiddleware } from 'redux';
import reducers from '../../redux';
import thunk from 'redux-thunk';

const createStore = () => reduxCreateStore(reducers, applyMiddleware(thunk));

export default ({ element }) => (
  <Provider store={createStore()}>{element}</Provider>
);