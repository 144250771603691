import axios from 'axios';
import { API_URL } from '../../Global_Config';

const RETRIEVE_MYACCOUNT_SUCCECESS = 'MYACCOUNT_RETRIEVED';
const RETRIEVE_MYACCOUNT_FAILED = 'MYACCOUNT_RETRIEVAL_FAILED';
const UPDATE_AVATAR_SUCCECESS = 'AVATAR_UPDATED';
const RETRIEVE_USER_SUCCECESS = 'USER_RETRIEVED';
const UPDATE_AVATAR_FAILED = 'AVATAR_FAILED_TO_UPDATE';
const UPDATE_MYACCOUNT_SUCCESS = "MYACCOUNT_UPDATED";
const UPDATE_MYACCOUNT_FAILED = "MYACCOUNT_FAILED_TO_UPDATE";
const REGISTER_PRODUCT_SUCCESS = "REGISTER_PRODUCT_SUCCESS";
const REGISTER_PRODUCT_FAILED = "REGISTER_PRODUCT_FAILED";
const REFRESHED = "REFRESHED";
const NEEDS_REFRESH = "NEEDS_REFRESH";
const CLEAR_ERROR = "CLEAR_ERROR";
const DEACTIVATE_SOFTWARE_SUCCESS = "DEACTIVATE_SOFTWARE";
const DEACTIVATE_SOFTWARE_FAILED = "DEACTIVATE_SOFTWARE_FAILED"
const initState = {};
const initUserState = {};

export function user(state=initState, action) {
    switch(action.type) {
        case RETRIEVE_MYACCOUNT_SUCCECESS:
            return { ...state, data: action.data, needsRefresh: false, retrieveSuccess: true, err: null };
        case RETRIEVE_MYACCOUNT_FAILED:
            state.err = action.data;
            return {...state}
        case UPDATE_AVATAR_SUCCECESS:
            return {...state, data: {...state.data, avatar: action.data}};
        case UPDATE_AVATAR_FAILED:
            return {...state};
        case UPDATE_MYACCOUNT_SUCCESS:
            state.updateSuccess = true
            state.err = null;
            return {...state, retrieveSuccess: false, needsRefresh: true};
        case UPDATE_MYACCOUNT_FAILED:
            state.updateSuccess = false
            state.err = action.data.data.message;
            return {...state};
        case REGISTER_PRODUCT_SUCCESS:
            state.err = null;
            return {...state, needsRefresh: true, retrieveSuccess: false};
        case REGISTER_PRODUCT_FAILED:
            state.err = action.data;
            return {...state};
        case CLEAR_ERROR:
            state.err = null;
            return {...state};
        case REFRESHED:
            state.needsRefresh = false;
            return {...state};
        case NEEDS_REFRESH:
            state.needsRefresh = true;
            state.retrieveSuccess = false;
            return {...state};
        case DEACTIVATE_SOFTWARE_SUCCESS:
            state.needsRefresh = true;
            state.retrieveSuccess = false;
            state.err = null;
            return {...state};
        case DEACTIVATE_SOFTWARE_FAILED:
            state.err = action.data;
            return {...state};
        default:
            return state;
    }
}


export function getUserInfo(state=initUserState, action) {
    switch(action.type) {
        case RETRIEVE_USER_SUCCECESS:
            state = action.data;
            return { ...state}
        default:
            return state;
    }
}

export function clearError() {
    return {type: CLEAR_ERROR};
}

export function refreshed() {
    return {type: REFRESHED};
}

export function needsRefresh() {
    return {type: NEEDS_REFRESH};
}

export function retrieveMyAccount() {
    if (typeof window !== 'undefined') {
        const token = localStorage.getItem('token');
        if (!token) {
            return {type : RETRIEVE_MYACCOUNT_FAILED};
        }
        let config = { 
            headers: { token: token }
        };
        return dispatch => {
            axios.get(`${API_URL}/myAccount`, config)
            .then( (res) => dispatch({
                type : RETRIEVE_MYACCOUNT_SUCCECESS,
                data : res.data
            })).catch(err => {
                dispatch({type:RETRIEVE_MYACCOUNT_FAILED, data : err.response})});
        };
    }
    
}

export function retrieveUserInfo(userID) {
    return dispatch => {
        axios.get(`${API_URL}/users/${userID}`)
        .then( (res) => dispatch({
            type : RETRIEVE_USER_SUCCECESS,
            data : res.data
        }));
    };
}



export function changeMyAvatar(avatar) {
    if (typeof window !== 'undefined') {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('avatar', avatar);
        let config = { 
            headers: { 
                token: token,
                'conetnet-type': 'multiplart/form-data',
            }
        };
        return dispatch => {
            dispatch({type: UPDATE_AVATAR_SUCCECESS})
            axios.post(`${API_URL}/updateAvatar`, formData, config)
            .then( (res) => 
            {
                dispatch({
                    type : UPDATE_AVATAR_SUCCECESS,
                    data : res.data,
                })
            },
            ).catch(err => {
                dispatch({
                    type : UPDATE_AVATAR_FAILED,
                    data : err.response,
                })
            });
        };
    } else {
        return {UPDATE_AVATAR_FAILED}
    }
    
}

export function changeMyAccount (userinfo) {
    if (typeof window !== 'undefined') {
        const token = localStorage.getItem('token');
        let config = { 
            headers: { 
                token: token,
            },
        };
        return dispatch => {
            axios.put(`${API_URL}/myAccount`, userinfo, config)
            .then( (res) => 
            {
                retrieveMyAccount();
                dispatch({
                    type : UPDATE_MYACCOUNT_SUCCESS,
                    data : res.data,
                })
            },
            ).catch(err => {
                dispatch({
                    type : UPDATE_MYACCOUNT_FAILED,
                    data : err.response,
                })
            });
        };
    } else {
        return {type: UPDATE_MYACCOUNT_FAILED}
    }
}


export function registerProduct (product) {
    if (typeof window !== 'undefined') {
        const token = localStorage.getItem('token');
        let config = { 
            headers: { 
                token: token,
            },
        };
        return dispatch => {
            axios.post(`${API_URL}/products`, product, config)
            .then( (res) => 
            {
                dispatch({
                    type : REGISTER_PRODUCT_SUCCESS,
                    data : res.data,
                })
            },
            ).catch(err => {
                dispatch({
                    type : REGISTER_PRODUCT_FAILED,
                    data : err.response,
                })
            });
        };
    } else {
        return {type: REGISTER_PRODUCT_FAILED}
    }
}

export function deactivate_software (softwareID, deviceID) {
    if (typeof window !== 'undefined') {
        const token = localStorage.getItem('token');
        let config = { 
            headers: { 
                token: token,
            },
            data: {
                softwareID: softwareID, 
                deviceID: deviceID,
            }
        };
        return dispatch => {
            axios.delete(`${API_URL}/software/removeDevice`, config)
            .then( (res) => 
            {
                dispatch({
                    type : DEACTIVATE_SOFTWARE_SUCCESS,
                })
            },
            ).catch(err => {
                dispatch({
                    type : DEACTIVATE_SOFTWARE_FAILED,
                    data : err.response,
                })
            });
        };
    } else {
        return {type: REGISTER_PRODUCT_FAILED}
    }
}