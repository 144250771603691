import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import dlow from '../assets/si_dlow.jpg'
import colaps from '../assets/si_colaps.jpg'
import bigman from '../assets/si_bigman.jpg'

class TeamPhoto extends Component {
    render() {
        return (
            <Carousel infiniteLoop={true} autoPlay={true} interval={5000} showThumbs={false} showStatus={false}>
                <div>
                    <img src={dlow} />
                    <p className="legend">Joe, Rex, D-Low, Villian, Alicia</p>
                </div>
                <div>
                    <img src={bigman} />
                    <p className="legend">Kento, Alicia, Joe, Bigman, Rex, Alan</p>
                </div>
                <div>
                    <img src={colaps} />
                    <p className="legend">Joe, Colaps, Rex, Alicia</p>
                </div>
            </Carousel>
        );
    }
}

export default TeamPhoto